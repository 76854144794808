import { DropdownOptions } from "./ag-models";

export interface UserModel {
  tenantId: number;
  userId: number;
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  isActive: boolean;
  isNewUser: boolean;
  isPasswordExpired: boolean;
  lastAccessDate: Date | string | null;
  passwordExpireDate: Date | string | null;
  createdOn: Date | string | null;
  quickId: string;
  roleId: number;
  languageId: number;
  email: string;
  loggedUser: string;
  propertyId: number;
  activatedBy?: number;
  activatedOn?: Date | string | null;
  deActivatedBy?: number;
  deActivatedOn?: Date | string | null;
  userPropertyAccesses: UserPropertyAccess[];
}


export interface UserPropertyAccess {
  userPropertyAccessID: number;
  userID: number;
  propertyID: string;
  subPropertyID: number;
  userAuthorityLevel: number;
  hasAccess: boolean;
  productId: number;
  roleId: number;
  equivalancyId: number;
  accountBlocked: boolean;
  autoLogOff: boolean;
  logOffAfter: number;
  lastAccessDate: Date | string | null;
  userSubPropertyAccess: UserSubPropertyAccess[];
}

export interface UserSubPropertyAccess {
  userSubPropertyAccessID: number;
  userPropertyAccessID: number;
  userID: number;
  hasAccess: boolean;
  subPropertyID: number;
}
export interface CMSPlayerInfo {
  playerID: string;
  personalDetails: PersonalDetails;
  cmsBalanceDetails: CMSBalanceDetails;
  smConfiguration: SMConfiguration;
  returnCode: SMReturnCode;
  debugMessage: string;
  message: string;
  vendorErrorCode: string;
}
export interface CMSMultiPlayerInfo {
  CMSPlayerInfo: CMSPlayerInfo[];
}

export interface CMSAccountInfo {
  accountId: string;
  accountName: string;
  cmsBalanceDetails: CMSBalanceDetails;
  returnCode: SMReturnCode;
  debugMessage: string;
  message: string;
  vendorErrorCode: string;
}

export enum SMReturnCode {
  Success = 0,
  InvalidCard = 1,
  InactiveCard = 2,
  InvalidAccount = 3,
  InactiveAccount = 4,
  InvalidPinNumber = 5,
  RequestedAmountNotAvailable = 6,
  RequestedAmountLimitExceeded = 7,
  RequestedAmountMustBeWholeCurrencyUnit = 8,
  RequestedPointsNotAvailable = 9,
  RequestedPointLimitExceeded = 10,
  InvalidVoucher = 11,
  VoucherLimitExceeded = 12,
  InvalidComp = 13,
  CompLimitExceeded = 14,
  FailedInvalidInput = 15,
  FailedAuthentication = 16,
  FailedVendorNetworkUnavailable = 17,
  FailedUnsupported = 18,
  FailedAdapterFailure = 19,
  FailedVendorFailure = 20,
  FailedUnknownFailure = 21,
  FailedOneOrMoreTransactionRequest = 22,
  NoCompsVouchersAvailable = 23,
}

export enum VendorErrorCode {
  Good = "GOOD"
}

export interface CMSPatronLookupRequest {
  playerId: string;
  pinNumber: string;
  includePointsCompsDetails: boolean;
  excludeFutureOffers?: boolean;
  outletProfitCenter?: Number;
  tenderId?: number;
}
export interface CMSMultiplePatronLookupRequest {
  playerId: string[];
  pinNumber: string[];
  includePointsCompsDetails: boolean[];
  excludeFutureOffers?: boolean;
  outletProfitCenter?: Number;
  tenderId?: number;
}

export interface CMSAccountLookupRequest {
  playerId: string;
  pinNumber: string;
  includePointsCompsDetails?: boolean;
  outletProfitCenter?: Number;
  tenderId?: number;
}

export interface SMConfiguration {
  siteId: string;
  url: string;
  isIntegrationUsingProxy: boolean;
  channelVersion: string;
  employeeId: string;
  profitCenterId: number;
  profitCenterName: string;
  allowOfferRedemption: boolean;
}

export interface PersonalDetails {
  firstName: string;
  lastName: string;
  pronounced: string;
  playerRank: string;
  dateOfBirth: string;
  gender: string;
  address: Address;
  email: Email[];
  phone: Phone[];
}

export interface Address {
  addressTypeId: number;
  addressType: string;
  addressLine1: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  countryAlpha2Code: string;
  countryAlpha3Code: string;
  countryNumericCode: number;
  countryName: string;
}

export interface Email {
  emailTypeId: number;
  emailAddress: string;
}

export interface Phone {
  phoneNumber: string;
  extension: string;
  countryCode: string;
  phoneTypeId: number;
  isPrimary: boolean
}

export interface CMSBalanceDetails {
  compRedemtion: CompRedemtion;
  offerRedemption: OfferRedemption;
}


export interface CompRedemtion {
  points: Points;
  comps: Comps;
}

export interface Comps {
  availableCompInDollars: number;
}

export interface Points {
  availablePointsInDollars: number;
}

export interface OfferRedemption {
  vouchers: Voucher[];
  coupons: Coupons;
}

export interface Coupons {
}

export interface Voucher {
  voucherId: string;
  voucherType: string;
  name: string;
  voucherAmount: number;
  voucherExpirationDate: string | Date;
  vendorType: string;
  playerPin?: string;
  transactionAmount?: number;
  outletProfitCenter?: number;
  offerPaymentMethodId?: number  //Id from CMS
}

export interface RedeemPointsRequest {
  playerId: string;
  playerPin: string;
  pointsType: string;
  amount: number;
  currencyCode: string;
  checkNumber: string;
  outletId: number;
  outletProfitCenter?: Number;
}


export const enum Product {
  SPA = 1,
  RETAIL = 2,
  GOLF = 3,
  COMMON = 4,
  SNC = 5,
  PMS = 6,
  ACCOUNTING = 10,
  Folio = 11
}

export enum TemplateTypes {
  Email = 1,
  SMS = 2,
  WhatsApp = 3
}

export interface PatchJson {
  op: PatchOperation;
  path: string;
  value: any;
}


export enum PatchOperation {
  add = "add",
  remove = "remove",
  replace = "replace",
  copy = "copy",
  move = "move",
  test = "test"
}
export enum AlertType {
  Success = 1,
  Warning = 2,
  Error = 3,
  WellDone = 4,
  Info = 5,
  AccessDenied = 6,
  Done = 7,
  Confirmation = 8,
  CustomDefault = 9,
  Attention = 10
}

export enum AlertAction {
  CONTINUE = 'CONTINUE',
  CANCEL = 'CANCEL',
  YES = 'YES',
  NO = 'NO'
}

export enum ButtonTypes {
  YesNo = 1,
  YesNoCancel = 2,
  OkCancel = 3,
  Ok = 4,
  SaveCancel = 5,
  Continue = 6,
  ContinueCancel = 7,
  AddCancel = 8,
  Save = 9,
  Update = 10
}

export interface SearchWithCheckbox {
  title: string;
  searchPlaceHolder: string;
  itemList: CheckboxConfig[];
  disabled?: boolean;
  showSearch: boolean;
  lists: CheckboxConfig[];
  showWarningBar?: boolean;
  warningMessage?: string;
  showSelectAll?: boolean;
}

export interface CheckboxConfig {
  value: number | string;
  viewValue: string;
  checked: boolean;
  searchValue?: string;
}
export class UserProperty {
  autoLogOff: boolean;
  currencyCode: string;
  isActive: boolean;
  languageCode: string;
  logOffAfter: number;
  platformPropertyId: string;
  platformTenantId: string;
  productId: number;
  profitCenter: string;
  propertyCode: string;
  propertyDate: Date;
  propertyId: number;
  propertyName: string;
  roleId: number;
  roleName: string;
  subPropertyCode: string;
  subPropertyId: number;
  subPropertyName: string;
  tenantId: number;
  timeZone: string;
  userId: number;
  sessionId: string;
  startOfWeek?: number;
  propTimeFormat?: number;
  platFormExtendedSearchRequired?: boolean;
}
export interface PropertyInformation {
  propertyId: number;
  propertyCode: string;
  propertyName: string;
  languageCode: string;
  tenantId: number;
  address1: string;
  address2: string;
  address3: string;
  state: string;
  city: string;
  country: string;
  zip: string;
}
export interface APIPrinterDetails {
  id: number;
  name: string;
  status: number;
  isDefault: boolean;
  isNetwork: boolean;
}
export interface PrintDocumentInput {
  reportData?: Uint8Array;
  printerName: string;
  documentName?: string;
  noOfCopies: number;
  printFormat?: string;
}
export interface DMSettings {
  id: number;
  extractDirectory: string;
  isDmSeachEnabled: boolean;
  printOption: number;
  docViewCodeAndConfigs: DmCodeAndSettings[]
}

export interface DmCodeAndSettings {
  searchEnabled: boolean;
  dmCodesId: number;
  dmCode: string;
  description: string;
  userClass: boolean;
  identifier: string;
  dmSettingsId: number;
  indexMapping: string
}

export interface DmCodeAndSettingsForUpdate {
  searchEnabled: boolean;
  dmCodesId: number;
  dmCode: string;
  description: string;
  userClass: boolean;
  identifier: string;
  dmSettingsId: number;
  indexMapping: string;
  DmDocTypes: string[]
}

export interface DMConfiguration {
  enableDataMagine: boolean;
  apiInformations: ApiInformation[];
  docTypes: AdditionalInfo[];
  indexInformations: AdditionalInfo[];
}
export interface AdditionalInfo {
  key: string;
  value: string;
}

export interface ApiInformation {
  serviceName: string;
  additionalInfo: AdditionalInfo[]
}
export interface IntegrationFormSetting {
  id: number;
  moduleName: string;
  screenName: string;
  configValue: string;
}
export interface IntegrationAPISettings<T> {
  id: number;
  moduleName: string;
  screenName: string;
  configValue?: T;
}

export interface SamsotechIntegrationConfig {
  enableSamsotech: boolean;
  enableFlipCard: boolean;
  scannerEndpointUrl: string;
  readChipEndpointUrl: string;
}

export namespace API {
  export interface PropertyInformation {
    propertyId: number;
    propertyCode: string;
    propertyName: string;
    languageCode: string;
    tenantId: number;
    address1: string;
    address2: string;
    address3: string;
    state: string;
    city: string;
    country: string;
    latitude: string;
    longitude: string;
    zip: string;
    propertyContacts: PropertyContact[];
    propertyEmail: Email[];
    county: string;
    arrivalTime: string,
    checkOutTime: string,
    temperatureScale: boolean;  //REQUIREMENT 61230
    imageReferenceId?: string;
    propertyImageAlign: PropertyImageAlign;
  }

  export interface PropertyContact {
    id?: number;
    clientId: number;
    contactTypeId: number | string;
    number: string;
    propertyId: number;
  }
  export interface Email {
    id?: number;
    emailType: number;
    emailId: string;
    propertyId: number;
  }

  export interface Language {
    id: number;
    languageCode: string;
    languageName: string;

  }

  export interface PropertyDetails {
    contextId: string;
    currencyId: number;
    id: number;
    location: PropertyLocation;
    locationId: number;
    platformContextId: string;
    propertyAddress: null;
    propertyCode: string;
    propertyContacts: PropertyContact[];
    propertyName: string;
    propertyProducts: [];
    propertySettings: PropertySetting[];
  }

  export interface PropertySetting {
    id: number;
    propertyId: number;
    productId: number;
    propertyDate: Date;
    activateRetailInterface: boolean;
    VATEnabled: boolean;
    productVersion: string;
  }

  export interface PropertyLocation {
    id: number;
    locationCode: string;
    locationName: string;
    languageId: number;
    timeZone: string;
  }

  export interface Authorization {
    token: string;
    userId: string;
  }

}
export enum PropertyImageAlign {
  None = 0,
  Top_Left = 1,
  Top_Center = 2,
  Top_Right = 3
}

export interface DmConfig {
  id: number;
  enableDataMagine: boolean;
  product: Product;
  serverURL: string;
  userName: string;
  password: string;
  scannerEndpoint: string;
  captureSignature: boolean;
  signatureCaptureEndpoint: string;
  enableDmLink: boolean;
  enableIDScan: boolean;
  enableSigCap: boolean;
  showPreview: boolean;
  postCheckReceiptToDM: boolean;
  viewName: string;
  dmEformsConfig: DmEformsConfig;
  sncDataMagineAdditonalModel?: SNCDataMagineModel;
}
export interface SNCDataMagineModel {
  supportedLetter: Number[] | DropdownOptions[];
  bookingViewText: string;
  accountViewText: string;
  proformaViewText: string;
  beoViewText: string;
  beoCheckViewText: string,
  accountDocSignExpiaryDate: Number,
  bookingDocSignExpiaryDate: Number,
  proformaDocSignExpiaryDate: Number,
  beoDocSignExpiaryDate: Number,
  beoCheckDocSignExpiaryDate: Number
}


export interface DmDocument {
  PCname: string;
  Viewname: string;
  Indexvaluelist: Indexvaluelist[];
  Pagesize: number;
  Pagenum: number;
  DateDelimiter: string;
}

export interface Indexvaluelist {
  Name: string;
  Operator: string;
  Value: string;
  Connector: string;
}

export interface DmSigCapture {
  transactionId: string;
  resId: string;
  signatureFile: string;
  code: string;
  message: string;
  signatureFileExt: string;
  screenType: ScreenType;
}

export enum ScreenType {
  PMSSTAY = 0
}


export interface DmIntegrationResponse {
  dmConfig: DmConfig;
  dmCodeAndSettings: DmCodeAndSettings;
  eformConfig: EformConfig;
}

export enum PMSField {
  arrivalDate = 1,
  bookingDate = 2,
  bookingId = 3,
  bookingName = 4,
  building = 5,
  changeDate = 6,
  confirmationNumber = 7,
  createDate = 8,
  departureDate = 9,
  documentType = 10,
  establishedDate = 11,
  fileName = 12,
  firstName = 13,
  lastName = 14,
  propertyCode = 15,
  referenceId = 16,
  roomNumber = 17,
  roomType = 18,
  stayId = 19,
  userClass = 20,
  workOrderId = 21,
  linkingId = 22,
  reference = 23,
  entereddate = 24,
  entity = 25,
  type = 26,
  fileId = 27
}

export interface KeyValuePair {
  key: string,
  value: string
}

export interface AccountingConfiguration {
  isEnabled: boolean;
  values: KeyValuePair[]
}
export interface DocViewCodeAndConfig {
  isSearchEnabled: boolean;
  codeId: number;
  code: string;
  description: string;
  userClass: boolean;
  identifier: string;
  configId: number;
}

export interface DmEformsConfig {
  id: number,
  dmConfigId: number;
  enableEforms: boolean;
  showDuringCheckIn: boolean;
  showPreview: boolean;
  eformsTenantId: string;
  eformsPropertyId: string;
  authURL: string;
  eformsListURL: string;
  eformsLinkURL: string;
  clientId: string;
  clientSecret: string;
  enableEformSigCap: boolean;
  showSigCapDuringCheckIn: boolean;
  enableSigCapDefaultTemplate: boolean;
  sigCapDefaultTemplate: string;
  sigCapUrl: string;
}


export interface DmEformsConfig {
  id: number,
  dmConfigId: number;
  enableEforms: boolean;
  showDuringCheckIn: boolean;
  showPreview: boolean;
  eformsTenantId: string;
  eformsPropertyId: string;
  authURL: string;
  eformsListURL: string;
  eformsLinkURL: string;
  clientId: string;
  clientSecret: string;
  enableEformSigCap: boolean;
  showSigCapDuringCheckIn: boolean;
  enableSigCapDefaultTemplate: boolean;
  sigCapDefaultTemplate: string;
  sigCapUrl: string;
}

export interface EformConfig {
  id: number,
  enableEform: boolean;
  authenticationURL: string;
  systemURL: string;
  documentURL: string;
  userName: string;
  password: string;
  eformsTenantId: string;
  eformsPropertyId: string;
}

export interface NewPasswordDetail {
  userName: string;
  newPassword: string;
  tenantId: string | number;
  propertyId?: number;
  oldPassword?: string;
  isPasswordEncrypted?: boolean;
}

export interface IntegrationOperationsLog {
  requestId?: string;
  typeId?: number;
  tenderId?: number;
  type?: IntegrationOperationsTransactionType;
  route?: string;
  request?: string;
  response?: string;
  status?: IntegrationOperationsStatus;
  hostName?: string;
  agentVersion?: string;
  ipAddress?: string;
  amount?: number;
  ticketNumber?: string;
}


export enum IntegrationOperationsStatus {
  Initiated = 1,
  Completed,
  Realized,
  Rejected,
  Reversed,
  Purged
}

export enum IntegrationOperationsTransactionType {

  //Retail Transactions
  CreditCardSalePaymentForRetailTransaction = 1,
  CreditCardCreditPaymentForRetailTransaction,
  CreditCardRefundPaymentForRetailTransaction,
  CreditCardVoidPaymentForRetailTransaction,
  GiftCardTopupForRetailTransaction,
  GiftCardRedemptionForRetailTransaction,
  GiftCardIssueForRetailTransaction,
  SaleByTokenForRetailTransaction,
  CreditByTokenForRetailTransaction,

  //Folio Postings
  CreditCardSalePaymentForFolioPosting = 101,
  CreditCardCreditPaymentForFolioPosting,
  CreditCardRefundPaymentForFolioPosting,
  CreditCardVoidPaymentForFolioPosting,
  GiftCardTopupForFolioPosting,
  GiftCardRedemptionForFolioPosting,
  GiftCardIssueForFolioPosting,
  CreditCardAuthForFolioPosting

}

export const PMAgentURL = {
  Sale: "sale",
  Credit: "credit",
  Load: "load",
  Issue: "issue",
  Auth: "auth"
}


export interface viewIndexDetails {
  doNotShowSearchResultBox: string;
  groupSearchResult: string;
  securityLevel: number;
  sortIndexColumn: number;
  viewDescription: string;
  viewName: string;
  viewFolderNameList: string[];
  viewIndexes: viewIndex[]
}

export interface viewIndex {
  indexLength: number;
  indexNumber: number;
  indexTitle: string;
  indexType: string;
  isReadOnly: boolean;
  isRequired: boolean;
  predefinedValues: string[]
  order: string;

}

export interface samsotechScanResponse {
  result: boolean;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  issueCountry: string;
  issueCountry_code2: string;
  issueCountry_fullname: string;
  nationality: string;
  nationality_code2: string;
  nationality_fullname: string;
  documentNumber: string;
  personalNumber: string;
  issueDate: string;
  expiryDate: string;
  optionalData1: string;
  optionalData2: string;
  personalEyeColor: string;
  personalHeight: string;
  issuingPlace: string;
  placeOfBirth: string;
  countryOfBirth: string;
  idType: string;
  errorMessage: string;
  fullImage: string;
  faceImage: string;
  errorCode: string;
  cardNumber: string;
  visaNumber: string;
  arabicName: string;
  mobileNumber: string;
  fathersName: string;
  mothersName: string;
  registeredCity: string;
  registeredTown: string;
  fullName: string;
  arabicDocumentNumber: string;
  arabicNationality: string;
  idCardType: string;
  occupation: string;
  CompanyNameArabic: string;
  FieldofStudyArabic: string;
  FieldofStudyEnglish: string;
  PassportIssueCountryDescriptionArabic: string;
  QualificationLevelDescriptionArabic: string;
  CompanyNameEnglish: string;
  OccupationField: string;
  PassportIssueCountry: string;
  PassportIssueCountryDescriptionEnglish: string;
  PassportExpiryDate: string;
  PassportIssueDate: string;
  PassportNumber: string;
  PassportType: string;
  QualificationLevel: string;
  QualificationLevelDescriptionEnglish: string;
  ResidencyExpiryDate: string;
  ResidencyNumber: string;
  ResidencyType: string;
  SponsorNumber: string;
  SponsorType: string;
  HomeAreaCode: string;
  HomeAddressTypeCode: string;
  HomeAreaDescriptionArabic: string;
  HomeAreaDescriptionEnglish: string;
  HomeCityCode: string;
  HomeCityDescriptionArabic: string;
  HomeCityDescriptionEnglish: string;
  EmirateCode: string;
  EmirateDescriptionArabic: string;
  POBox: string;
  HomeStreetArabic: string;
  HomeStreetEnglish: string;
  EmirateDescriptionEnglish: string;
  spouseName: string;
  martialStatus: string;
  documentTypeAbrevation: string;
  genderAbrevation: string;
  error_code: 1019;
  error_message: string;
  DocumentResult: string;
  fullImageIR: string;
  AuthenticationAlerts: string;
  fullImageUV: string;
  IsExpired: boolean;
  age: 0;
  lastName1: string;
  lastName2: string;
  Email: string;
  BirthName: string;
  DOB: Date;
  ErrorDetail: string;
  ExpiryDate: Date;
  FamilyName: string;
  GivenName: string;
  IssueDate: Date;
}

export interface OverrideDetail {
  OverrideDate: Date;
  Price: number
}



